<template>
    <div
      v-if="
        ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.tesouraria == 'S') &&
          $store.state.fastPermissoes.ativo == 'S' &&
          $store.state.fastPlataformaModulos.modulo_secretaria
      "
    >
      <div class="fd-app-wrap">
        <div class="container">
          <!-- breadcrumb -->
          <nav>
    
            <ul class="fd-app-breadcrumb">
              <li class="fd-app-breadcrumb-item">
                <a
                  href="/minhas-plataformas"
                  class="aluno_font_color"
                  @click.prevent="$router.push('/minhas-plataformas')"
                >Minhas Plataformas</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
                >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
              </li>
              <li class="fd-app-breadcrumb-item">
                <a
                  class="aluno_font_color"
                  href="#"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >Modo secretaria</a>              
              </li>
              <li class="fd-app-breadcrumb-item active">
                <a
                  class="aluno_font_color"
                  href="#"
                >Relatórios - Requisições Alunos</a>
              </li>
            </ul>
          </nav>
  
          <!-- /breadcrumb -->
        </div>
        <div class="fd-mode-adm-content mt-5 container">
          
          <!-- Conteúdo Principal Relatório -->
          <transition name="slide-fade">
            <div v-if="fastNavegacao.exibirRelatorio">
              <section class="fd-app-welcome text-left">
                  <h2><b>Relatório Requisições Alunos</b></h2>
                  <img
                    class="mb-3"
                    :src="require('@/assets/images/form-effect-01.png')"
                    style="max-height: 16px"
                  >
                </section>
                <div class="d-flex">
                  <div class="align-self-start mr-auto mb-2">
                    <button v-if="this.fastRequisicoesRel.filtroEscolhido.matriculaAluno"
                      @click="fastRequisicoesRel.filtroEscolhido.matriculaAluno = '', getRequisicoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Matrícula: {{ this.fastRequisicoesRel.filtroEscolhido.matriculaAluno }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRequisicoesRel.filtroEscolhido.idTipoRequerimento"
                      @click="fastRequisicoesRel.filtroEscolhido.idTipoRequerimento = '', getRequisicoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Turma: <template 
                              v-for="turma in fastRequisicoesRel.filtros.turmas"
                              :value="turma.id_turma">
                              <span v-if="fastRequisicoesRel.filtroEscolhido.idTipoRequerimento == turma.id_turma">{{ turma.descricao_turma }} </span>
                            </template>
                        <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRequisicoesRel.filtroEscolhido.idCurso"
                      @click="fastRequisicoesRel.filtroEscolhido.idCurso = '', getRequisicoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Curso: <template 
                              v-for="curso in fastRequisicoesRel.filtros.cursos"
                              :value="curso.id_secretaria_curso">
                              <span v-if="fastRequisicoesRel.filtroEscolhido.idCurso == curso.id_secretaria_curso">{{ curso.nome_curso }} </span>
                            </template>
                        <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRequisicoesRel.filtroEscolhido.statusAprovado === 'true'"
                      @click="fastRequisicoesRel.filtroEscolhido.statusAprovado = '', getRequisicoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Status: Aprovado <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRequisicoesRel.filtroEscolhido.statusAprovado === 'false'"
                      @click="fastRequisicoesRel.filtroEscolhido.statusAprovado = '', getRequisicoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Status: Reprovado <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRequisicoesRel.filtroEscolhido.anoMatricula"
                      @click="fastRequisicoesRel.filtroEscolhido.anoMatricula = '', getRequisicoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Ano Matrícula: {{this.fastRequisicoesRel.filtroEscolhido.anoMatricula}} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                    <button v-if="this.fastRequisicoesRel.filtroEscolhido.nomeAluno"
                      @click="fastRequisicoesRel.filtroEscolhido.nomeAluno = '', getRequisicoesRel()" type="button"
                      class="btn btn-sm btn-outline-secondary text-capitalize rounded-pill mr-2 mb-2">
                      Aluno: {{ this.fastRequisicoesRel.filtroEscolhido.nomeAluno }} <b-icon icon="x-lg" font-scale="0.7"
                        class="mb-1"></b-icon>
                    </button>
                  </div>
                </div>
              <section>
                <div class="row">
                  <form class="form col-sm-12 col-md-12 col-lg-6 pl-3 pr-3">
                    <label class="text-secondary mb-0 mt-2"><small>Plataforma:</small></label>
                    <select
                      class="form-control form-control-sm"
                      v-model="fastRequisicoesRel.filtroEscolhido.idPlataforma"
                      @change="getRequerimentos(), getCursos()" >
                      <option>Todas</option>
                      <option v-for="(plataforma, index) in fastRequisicoesRel.filtros.plataformas" :key="index"
                        :value="plataforma.id_plataforma">
                        {{ plataforma.nome_plataforma }}
                      </option>
                    </select>
                    <label class="text-secondary mb-0 mt-2"><small>Aluno:</small></label>
                    <input
                    type="text"
                    v-model="fastRequisicoesRel.filtroEscolhido.nomeAluno"
                    class="form-control form-control-sm"
                    />
                    <label class="text-secondary mb-0 mt-2"><small>Tipo do Requerimento:</small></label>
                    <select
                      :class="fastRequisicoesRel.filtroEscolhido.idTipoRequerimento ? 'form-control form-control-sm border border-primary text-primary' : 'form-control form-control-sm'"
                      v-model="fastRequisicoesRel.filtroEscolhido.idTipoRequerimento" >
                      <option value ="">Todas</option>
                      <option v-for="(req, index) in fastRequisicoesRel.filtros.requerimentos" :key="index"
                        :value="req.id_tipo_requerimento">
                        {{ req.nome_requerimento }}
                      </option>
                    </select>
                  </form>
                  <form class="form col-sm-12 col-md-12 col-lg-6 pl-3 pr-3">
                    <label class="text-primary mb-1 mt-2 row">
                      <small class="text-primary ml-0 pl-3 col-8">Data Solicitação:</small>
                      <small
                      class="col-4 text-right mr-0 pr-3"
                      role="button"
                      v-if="fastRequisicoesRel.filtroEscolhido.dataRegistroStart || fastRequisicoesRel.filtroEscolhido.dataRegistroEnd" 
                      @click="fastRequisicoesRel.filtroEscolhido.dataRegistroStart = '', fastRequisicoesRel.filtroEscolhido.dataRegistroEnd = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                    <div class="row pl-3 pr-3">
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataRegistroStart, fastRequisicoesRel.filtroEscolhido.dataRegistroEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataRegistroStart, fastRequisicoesRel.filtroEscolhido.dataRegistroEnd, true)"
                      v-model="fastRequisicoesRel.filtroEscolhido.dataRegistroStart"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data inicial"
                      size="sm"
                      placeholder="Selecionar data inicial"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataRegistroStart, fastRequisicoesRel.filtroEscolhido.dataRegistroEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataRegistroStart, fastRequisicoesRel.filtroEscolhido.dataRegistroEnd, true)"
                      v-model="fastRequisicoesRel.filtroEscolhido.dataRegistroEnd"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data final"
                      size="sm"
                      placeholder="Selecionar data final"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                    </div>
                    <label class="text-primary mb-1 mt-2 row">
                      <small class="text-primary ml-0 pl-3 col-8">Data Inicial Análise:</small>
                      <small
                      class="col-4 text-right mr-0 pr-3"
                      role="button"
                      v-if="fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseStart || fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseEnd" 
                      @click="fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseStart = '', fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseEnd = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                    <div class="row pl-3 pr-3">
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseStart, fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseStart, fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseEnd, true)"
                      v-model="fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseStart"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data inicial"
                      size="sm"
                      placeholder="Selecionar data inicial"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseStart, fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseStart, fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseEnd, true)"
                      v-model="fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseEnd"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data final"
                      size="sm"
                      placeholder="Selecionar data final"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                    </div>
                    <label class="text-primary mb-1 mt-2 row">
                      <small class="text-primary ml-0 pl-3 col-8">Data Final Análise:</small>
                      <small
                      class="col-4 text-right mr-0 pr-3"
                      role="button"
                      v-if="fastRequisicoesRel.filtroEscolhido.dataFimAnaliseStart || fastRequisicoesRel.filtroEscolhido.dataFimAnaliseEnd" 
                      @click="fastRequisicoesRel.filtroEscolhido.dataFimAnaliseStart = '', fastRequisicoesRel.filtroEscolhido.dataFimAnaliseEnd = ''"
                      >Limpar <b-icon icon="x-circle" scale="1.1" variant="danger"></b-icon></small>
                    </label>
                    <div class="row pl-3 pr-3">
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataFimAnaliseStart, fastRequisicoesRel.filtroEscolhido.dataFimAnaliseEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataFimAnaliseStart, fastRequisicoesRel.filtroEscolhido.dataFimAnaliseEnd, true)"
                      v-model="fastRequisicoesRel.filtroEscolhido.dataFimAnaliseStart"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data inicial"
                      size="sm"
                      placeholder="Selecionar data inicial"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                      <b-form-datepicker 
                      :class="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataFimAnaliseStart, fastRequisicoesRel.filtroEscolhido.dataFimAnaliseEnd) ? 'col-6 date-picker-filtro' : 'col-6 date-picker-filtro border-danger text-red'"
                      @input="validaDataFiltro(fastRequisicoesRel.filtroEscolhido.dataFimAnaliseStart, fastRequisicoesRel.filtroEscolhido.dataFimAnaliseEnd, true)"
                      v-model="fastRequisicoesRel.filtroEscolhido.dataFimAnaliseEnd"
                      locale="pt-BR"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                      label-no-date-selected="Selecionar data final"
                      size="sm"
                      placeholder="Selecionar data final"
                      label-help="Use o cursor para selecionar"
                      ></b-form-datepicker>
                    </div>
                  </form>
                </div>
                <div class="row">
                  <div class="text-left col-6 mt-4 mb-3">
                    <b-button @click="exportaPlanilha()" variant="custom-outline-primary btn-sm text-capitalize rounded">Exportar</b-button>                       
                  </div>
                  <div class="text-right col-6 mt-4 mb-3">
                    <b-button @click="limpaFiltros()" variant="custom-outline-primary btn-sm text-capitalize rounded mr-3">Limpar Filtros</b-button>
                    <b-button @click="getRequisicoesRel()" variant="custom-primary btn-sm text-capitalize rounded">Filtrar</b-button>                       
                  </div>
                </div>
              </section>

                <div class="row">
                  <div class="col-12 table-responsive">
                    <!--tabela oculta sem paginação para exportar-->
                    <table id="relatorio" class="table d-none">
                      <thead>
                        <tr>
                          <th><small>Data Solicitação</small></th>
                          <th><small>Tipo Requerimento</small></th>
                          <th><small>Nome Aluno</small></th>
                          <th><small>Detalhe Requerimento</small></th>
                          <th><small>Valor</small></th>
                          <th><small>Taxa</small></th>
                          <th><small>Desconto</small></th>
                          <th><small>Total Requerimento</small></th>
                          <th><small>Início Análise</small></th>
                          <th><small>Fim Análise</small></th>
                          <th><small>Data Pagto.</small></th>
                          <th><small>Rejeitado</small></th>
                          <th><small>Link Documento</small></th>
                        </tr>
                      </thead>
                  <tbody>
                    <tr
                      v-for="(e, index) in fastRequisicoesRel.filtro"
                      :key="index"
                    >
                    <td><small>{{ formataDataT(e.data_registro) }}</small></td>
                    <td><small>{{ e.nome_requerimento }}</small></td>
                    <td><small>{{ e.nome_aluno }}</small></td>
                      <td>     
                        <small v-if="e.detalhe_requerimento">{{ e.detalhe_requerimento }}</small>
                        <small v-else>-</small>                               
                      </td>
                      <td>
                        <small v-if="e.valor_requerimento">{{ formataPreco(e.valor_requerimento) }}</small>     
                        <small v-else>{{ formataPreco(0) }}</small>  
                      </td>
                      <td>
                        <small v-if="e.taxa">{{ formataPreco(e.taxa) }}</small>     
                        <small v-else>{{ formataPreco(0) }}</small>  
                      </td>
                      <td>
                        <small v-if="e.desconto">{{ formataPreco(e.desconto) }}</small>     
                        <small v-else>{{ formataPreco(0) }}</small>  
                      </td>
                      <td>
                        <small v-if="e.total_requerimento">{{ formataPreco(e.total_requerimento) }}</small>     
                        <small v-else>{{ formataPreco(0) }}</small>  
                      </td>
                      <td><small>{{ formataDataT(e.data_inicio_analise) }}</small></td>
                      <td><small>{{ formataDataT(e.data_fim_analise) }}</small></td>
                      <td><small>{{ formataDataT(e.data_pagto) }}</small></td>
                      <td><small>{{ formataDataT(e.data_rejeicao) }}</small></td>
                      <td>     
                        <small v-if="e.path_anexo">{{ e.path_anexo }}</small>
                        <small v-else>-</small>                               
                      </td>
                    </tr>
                  </tbody>
                    </table>
                    <table class="table table-sm text-nowrap table-hover">
                      <thead class="thead-dark" v-if="fastRequisicoesRel.loading">
                        <tr>
                          <th
                            colspan="12"
                            class="text-center"
                          >
                          <b-icon icon="gear-fill" animation="spin"/> Carregando 
                          </th>
                        </tr>
                      </thead>
                      <thead class="thead-dark" v-if="!fastRequisicoesRel.loading">
                        <tr>
                          <th class="pl-3 pt-2">
                            <b-form-checkbox v-model="fastRequisicoesRel.selectAll"/>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRequisicoesRel.orderBy.data_solicitacao !== 0 }" @click="orderByColumn('data_solicitacao')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Data Solicitação&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.data_solicitacao === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.data_solicitacao === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRequisicoesRel.orderBy.tipo_requerimento !== 0 }" @click="orderByColumn('tipo_requerimento')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Tipo Requerimento&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.tipo_requerimento === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.tipo_requerimento === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRequisicoesRel.orderBy.nome_aluno !== 0 }" @click="orderByColumn('nome_aluno')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Aluno&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.nome_aluno === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.nome_aluno === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                              Detalhe Requerimento
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRequisicoesRel.orderBy.valor !== 0 }" @click="orderByColumn('valor')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Valor&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.valor === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.valor === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRequisicoesRel.orderBy.taxa !== 0 }" @click="orderByColumn('taxa')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Taxa&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.taxa === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.taxa === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRequisicoesRel.orderBy.desconto !== 0 }" @click="orderByColumn('desconto')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Desconto&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.desconto === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.desconto === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRequisicoesRel.orderBy.total_req !== 0 }" @click="orderByColumn('total_req')" role="button" class="font-weight-bold d-flex pb-1 justify-content-center">
                              Total Req.&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.total_req === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.total_req === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRequisicoesRel.orderBy.data_analise_inicio !== 0 }" @click="orderByColumn('data_analise_inicio')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Inicio Análise&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.data_analise_inicio === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.data_analise_inicio === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRequisicoesRel.orderBy.data_analise_fim !== 0 }" @click="orderByColumn('data_analise_fim')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Fim Análise&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.data_analise_fim === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.data_analise_fim === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRequisicoesRel.orderBy.data_pagto !== 0 }" @click="orderByColumn('data_pagto')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Data Pagto.&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.data_pagto === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.data_pagto === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small :class="{ active: this.fastRequisicoesRel.orderBy.data_rejeitado !== 0 }" @click="orderByColumn('data_rejeitado')" role="button"  class="font-weight-bold d-flex pb-1 justify-content-center">
                              Rejeitado&nbsp;
                              <div class="d-flex flex-column">
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.data_rejeitado === -1" icon="caret-up-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-up" font-scale="0.7"></b-icon>
                                <b-icon v-if="this.fastRequisicoesRel.orderBy.data_rejeitado === 1" icon="caret-down-fill" font-scale="0.7"></b-icon>
                                <b-icon v-else icon="caret-down" font-scale="0.7"></b-icon>
                              </div>
                            </small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold d-flex pb-1 justify-content-center">
                              Link Documento
                            </small>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="fastRequisicoesRel.loading">
                    <tr>
                      <td
                        colspan="12"
                        class="text-center"
                      >
                      <b-icon icon="gear-fill" animation="spin"/> Carregando 
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="fastRequisicoesRel.paginacao.length">
                    <tr
                      v-for="(e, index) in fastRequisicoesRel.paginacao"
                      :key="index"
                    >
                      <td class="pl-3 pt-2"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      >
                        <b-form-checkbox role="button" type="checkbox" v-model="fastRequisicoesRel.selectedItems" :value="e.id"/>
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small>{{ formataDataT(e.data_registro) }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small>{{ e.nome_requerimento }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small>{{ e.nome_aluno }}</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small v-if="e.detalhe_requerimento" >
                          <a role="button" title="DETALHE REQUERIMENTO" @click.prevent="exibeModalVerDetalhe(e.detalhe_requerimento)" class="text-primary"><u>Ver</u></a>
                        </small>
                        <small v-else>-</small>                                  
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small v-if="e.valor_requerimento">{{ formataPreco(e.valor_requerimento) }}</small>     
                        <small v-else>{{ formataPreco(0) }}</small>                                
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small v-if="e.taxa">{{ formataPreco(e.taxa) }}</small>     
                        <small v-else>{{ formataPreco(0) }}</small>                                
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small v-if="e.desconto">{{ formataPreco(e.desconto) }}</small>     
                        <small v-else>{{ formataPreco(0) }}</small>                                
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                        
                      >
                        <small v-if="e.total_requerimento">{{ formataPreco(e.total_requerimento) }}</small>     
                        <small v-else>{{ formataPreco(0) }}</small>                                
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                          
                      >
                          <small>{{ formataDataT(e.data_inicio_analise) }}</small>                            
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                          
                      >
                          <small>{{ formataDataT(e.data_fim_analise) }}</small>                            
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                          
                      >
                          <small>{{ formataDataT(e.data_pagto) }}</small>                            
                      </td>
                      <td class="align-middle text-center"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                        role="button"
                          
                      >
                          <small>{{ formataDataT(e.data_rejeicao) }}</small>                            
                      </td>
                      <td class="align-middle text-center pl-2"
                        v-b-tooltip.hover.up.html="{title: criaTooltip(e), delay: {show: 1000}}"
                      >
                        <div v-if="e.path_anexo">
                          <a title="ABRIR DOCUMENTO" :href="e.path_anexo" target="_blank" class="text-dark mr-2"><b-icon icon="file-earmark-pdf" font-scale="1" ></b-icon></a>
                          <a title="COPIAR URL DOCUMENTO" href="#" @click.prevent="copyURLBoleto(e.path_anexo); exibeToasty('Documento copiado para a Área de Transferência!','success')" target="_blank" class="text-dark"><b-icon icon="back" font-scale="1"></b-icon></a>
                        </div>
                        <small v-else>-</small>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td
                        colspan="12"
                        class="text-center w-100"
                      >
                        Nenhuma requisição encontrada
                      </td>
                    </tr>
                  </tbody>
                    </table>
                  </div>
                  <div class="col-12 text-right mt-4">
                      <div class="d-inline">
                        <h6 class="font-weight-bold d-inline">Valor Total Requerimentos: </h6>
                        <h5 class="d-inline"><b-badge variant="primary">R$ {{ formataPreco(totalRequerimentos) }}</b-badge></h5>
                      </div>
                      <div class="mt-2 mb-2">
                        <h6 class="font-weight-bold d-inline">Valor Total Pago: </h6>
                        <h5 class="d-inline"><b-badge variant="success">R$ {{ formataPreco(totalPago) }}</b-badge></h5>
                      </div>
                  </div>
                  
                  <div class="col-12 text-center mt-4">
                <Pagination
                  :page-size="20"
                  :items="fastRequisicoesRel.filtro"
                  @changePage="fastRequisicoesRel.paginacao = $event"
                />
              </div>
                </div>
            </div>
          </transition>
          <!-- /Conteúdo Principal Relatório -->

          <div class="mt-5 mb-5">
            <a
              v-if="fastNavegacao.exibirRelatorio" 
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="
                '/plataforma/' + $route.params.id_plataforma + '/secretaria' 
              "
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>

        </div>

        <!--modals-->
        <modal
          name="modalVerLog"
          :scrollable="true"
          height="auto"
          :shift-y="0.3"
          :focus-trap="true"
          :adaptive="true"
        >
          <div class="row p-4">
            <div class="col-10 pl-4 pr-4">
              <h4>Detalhes - Requerimento</h4>
            </div>
            <div class="col-2 pl-4 pr-4 text-right">
              <a
                class="btn btn-secondary"
                href="#"
                @click.prevent="hideModal('modalVerLog')"
              >
                <b-icon-arrow-return-left />
              </a>
            </div>
            <div class="col-12 p-4">
              <div class="row">
                <div class="col-12 mt-4 mb-4">

                  <p class="text-info">
                    {{ fastRequisicoesRel.detalhesRequerimento }}
                  </p>

                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <button class="btn btn-secondary mr-2" @click="hideModal('modalVerLog'); fastLinkClipboard = false">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </modal>

      </div>
    </div>
  </template>
  
  <script>
  import { settings } from "@/config";
  import methods from "@/methods";
  import Pagination from "../components/Pagination";
  import DetalhesRecebivel from "../components/DetalhesRecebivel.vue"
  import * as XLSX from 'xlsx';
  
  export default {
    name: "HomeInternoTesourariaRelatoriosMatriculas",
    components: {
      Pagination,
      DetalhesRecebivel
    },
    mixins: [methods],
    data: function () {
      return {
        modalWidthSm: this.isMobile() ? "80%" : "30%",
        modalWidthMd: this.isMobile() ? "90%" : "50%",
        modalWidthLg: this.isMobile() ? "100%" : "90%",
        
        fastLinkClipboard: false,
        fastNavegacao: {
          exibirRelatorio : true,
        },
        fastRequisicoesRel: {
          loading: true,
          filtro: [],
          paginacao: [],
          filtroEscolhido : {
            idPlataforma: this.$route.params.id_plataforma,
            idTipoRequerimento: 0,
            nomeAluno: '',
            dataRegistroStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
            dataRegistroEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
            dataInicioAnaliseStart: null,
            dataInicioAnaliseEnd: null,
            dataFimAnaliseStart: null,
            dataFimAnaliseEnd: null,
          },
          filtros : {
            plataformas: [],
            requerimentos: [],
            datalistId: 'suggestions-list-' + Math.floor(Math.random() * 1000000),
          },
          detalhesRequerimento: '',
          gatewaysPagamento: [],
          orderBy: {
            tipo_requerimento: 0,
            nome_aluno: 0,
            valor: 0,
            taxa: 0,
            desconto: 0,
            total_req: 0,
            data_solicitacao: 0,
            data_analise_inicio: 0,
            data_analise_fim: 0,
            data_pagto: 0,
            data_rejeitado: 0
          },
          selectedItems: [],
          selectAll: false
        },
        fastInvoiceFiltrosDetalhes: {
          idPlataforma : '',
          idInvoice : '',
          parcela : '',
        },
        tableData : [],
      };
    },
    mounted: function () {
      if (this.$route.params.id_plataforma) {
        this.getFastDependecias(this.$route.params.id_plataforma, 'tesouraria')
          .then(() => {
            if (
              this.$store.state.fastPlataformaModulos.modulo_secretaria
            ) {            
              this.$store.state.fastCarregando = false;
              this.getFastHoraServidor()
              .then(() => {
                this.getPlataformasFilhas()
                this.getRequisicoesRel(this.$route.params.id_plataforma)
                this.getRequerimentos()
                this.getCursos()
              })
  
            } else {
              this.$store.state.fastCarregando = false;
              this.exibeToasty("Módulo não disponível para sua plataforma", "error");
            }
          })
          .catch((e) => {
            this.exibeToasty(e, "error");
            this.$store.state.fastCarregando = false;
          });
      }
    },
    computed: {
      totalRequerimentos() {
        return this.fastRequisicoesRel.filtro.reduce((total, item) => {
          if (!this.fastRequisicoesRel.selectedItems.length){
            return total + Number(item.total_requerimento)
          }
          if (this.fastRequisicoesRel.selectedItems.includes(item.id)) {
            return total + Number(item.total_requerimento)
          } else {
            return total
          }
        }, 0)
      },
      totalPago() {
        return this.fastRequisicoesRel.filtro.reduce((total, item) => {
          if (!this.fastRequisicoesRel.selectedItems.length){
            if (item.data_pagto) {
              return total + Number(item.total_requerimento);
            } else {
              return total;
            }
          }
          if (this.fastRequisicoesRel.selectedItems.includes(item.id)) {
            if (item.data_pagto) {
              return total + Number(item.total_requerimento);
            } else {
              return total;
            }
          } else {
            return total
          }
        }, 0)
      },
    },
    watch: {
      'fastRequisicoesRel.selectAll'(val) {
        if (val) {
          this.fastRequisicoesRel.selectedItems = this.fastRequisicoesRel.filtro.map((item) => item.id)
        } else {
          this.fastRequisicoesRel.selectedItems = []
        }
      }
    },
    methods: {
      getPlataformasFilhas(){
      //Pega plataformas filhas para o filtro
      this.promiseGetFastApi("api/fast_plataforma/lista_filhas_novo", `id_plataforma=${this.fastRequisicoesRel.filtroEscolhido.idPlataforma}`)
      .then((res) => {          
        if (res.Data.length) {
          this.fastRequisicoesRel.filtros.plataformas = res.Data;
        } else{
          this.fastRequisicoesRel.filtros.plataformas = this.$route.params.id_plataforma;
        }
      })
      .catch((e) => {
          console.log(e);
      });
      },
      getRequerimentos(){
        //Pega turmas para o filtro
        this.promiseGetFastApi("api/fast_secretaria_tipo_requerimento/lista", `id_plataforma=${this.fastRequisicoesRel.filtroEscolhido.idPlataforma}`)
        .then((res) => {          
          if (res.length) {
            this.fastRequisicoesRel.filtros.requerimentos = res;
          }else{
            this.fastRequisicoesRel.filtros.requerimentos = [];
          }
        })
        .catch((e) => {
            console.log(e);
        });
      },
      async getRequisicoesRel(){       
        this.fastRequisicoesRel.loading = true

        let plataforma;
        let todasPlataformasFilhas;

        todasPlataformasFilhas = this.fastRequisicoesRel.filtroEscolhido.idPlataforma === 'Todas';
        plataforma = todasPlataformasFilhas ? this.$route.params.id_plataforma : this.fastRequisicoesRel.filtroEscolhido.idPlataforma;

        this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_requisicao_aluno", `IdPlataforma=${plataforma ? plataforma : 0}&idTipoRequerimento=${this.fastRequisicoesRel.filtroEscolhido.idTipoRequerimento ? this.fastRequisicoesRel.filtroEscolhido.idTipoRequerimento : 0}&NomeAluno=${this.fastRequisicoesRel.filtroEscolhido.nomeAluno}&DataRegistroStart=${this.fastRequisicoesRel.filtroEscolhido.dataRegistroStart ? this.fastRequisicoesRel.filtroEscolhido.dataRegistroStart : ''}&DataRegistroEnd=${this.fastRequisicoesRel.filtroEscolhido.dataRegistroEnd ? this.fastRequisicoesRel.filtroEscolhido.dataRegistroEnd : ''}&DataInicioAnaliseStart=${this.fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseStart ? this.fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseStart : ''}&DataInicioAnaliseEnd=${this.fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseEnd ? this.fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseEnd : ''}&DataFimAnaliseStart=${this.fastRequisicoesRel.filtroEscolhido.dataFimAnaliseStart ? this.fastRequisicoesRel.filtroEscolhido.dataFimAnaliseStart : ''}&DataFimAnaliseEnd=${this.fastRequisicoesRel.filtroEscolhido.dataFimAnaliseEnd ? this.fastRequisicoesRel.filtroEscolhido.dataFimAnaliseEnd : ''}`)

        .then(res => {

          if(res.length) {

            //id para usar no select checkbox da tabela
            const listaAvaliacoes = res.map((item, index) => {
                return { ...item, id: index };
            });
  
            console.log("getRequisicoesRel", listaAvaliacoes)
  
            this.fastRequisicoesRel.filtro = todasPlataformasFilhas ? listaAvaliacoes : listaAvaliacoes.filter(item => item.id_plataforma == this.fastRequisicoesRel.filtroEscolhido.idPlataforma);

          } 
          else {
            this.fastRequisicoesRel.filtro = []
          }

          this.fastRequisicoesRel.loading = false

        }).catch(e => {
          console.log(e)
          this.exibeToasty(e, "error")
          this.fastRequisicoesRel.loading = false
        })
      },
      limpaFiltros(){

        this.fastRequisicoesRel.filtroEscolhido.idPlataforma = this.$route.params.id_plataforma
        this.fastRequisicoesRel.filtroEscolhido.idTipoRequerimento = ''
        this.fastRequisicoesRel.filtroEscolhido.nomeAluno = ''
        this.fastRequisicoesRel.filtroEscolhido.dataRegistroStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10)
        this.fastRequisicoesRel.filtroEscolhido.dataRegistroEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10)
        this.fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseStart = ''
        this.fastRequisicoesRel.filtroEscolhido.dataInicioAnaliseEnd = ''
        this.fastRequisicoesRel.filtroEscolhido.dataFimAnaliseStart = ''
        this.fastRequisicoesRel.filtroEscolhido.dataFimAnaliseEnd = ''

        this.getRequisicoesRel();
      },
      validaDataFiltro(inicio, fim, infErro){
        if(inicio === '' || fim === ''){
          return true
        } else if(inicio > fim){
          if (infErro) this.exibeToasty('A data inicial não pode ser maior que a data final', 'error');
          return false
        }
        return true;
      },
      exportaPlanilha(){
        const table = document.querySelector('#relatorio');
        const tableRows = table.querySelectorAll('tr');
        const tableData = [];

        for (let i = 0; i < tableRows.length; i++) {
          const tableCells = tableRows[i].querySelectorAll('td');
          const rowData = [];

          for (let j = 0; j < tableCells.length; j++) {
            rowData.push(tableCells[j].innerText);
          }

          tableData.push(rowData);
        }

        tableData[0] = ['Data Solicitação', 'Tipo Requerimento', 'Nome Aluno', 'Detalhe Requerimento', 'Valor', 'Taxa', 'Desconto', 'Total Req.', 'Inicio Análise', 'Fim Análise', 'Data Pagto.', 'Rejeitado', 'Link Documento'];

        const worksheet = XLSX.utils.aoa_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'FastEAD - relatorio-requisicoes-alunos.xlsx');
      },
      copyURLBoleto(link) {
      const el = document.createElement('textarea');  
      el.value = link;                                 
      el.setAttribute('readonly', '');                
      el.style.position = 'absolute';                     
      el.style.left = '-9999px';                      
      document.body.appendChild(el);                  
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
      el.select();                                    
      document.execCommand('copy');                   
      document.body.removeChild(el);                  
      if (selected) {                                 
        document.getSelection().removeAllRanges();    
        document.getSelection().addRange(selected);   
      }
      },
      orderByColumn(coluna) {
        Object.keys(this.fastRequisicoesRel.orderBy).forEach(key => {
          if (key !== coluna) {
            this.fastRequisicoesRel.orderBy[key] = 0;
          }
        });
        // Atualiza a ordem de classificação para a coluna clicada
        this.fastRequisicoesRel.orderBy[coluna] = this.fastRequisicoesRel.orderBy[coluna] === 1 ? -1 : 1;

        // Classifica a tabela de acordo com as colunas ordenadas e a ordem de classificação
        this.fastRequisicoesRel.filtro.sort((a, b) => {
          const order = this.fastRequisicoesRel.orderBy[coluna];
          
          if (coluna === 'tipo_requerimento') {
            return a.nome_requerimento.localeCompare(b.nome_requerimento) * order;
          } else if (coluna === 'nome_aluno') {
            return a.nome_aluno.localeCompare(b.nome_aluno) * order;
          } else if (coluna === 'valor') {
            return (a.valor_requerimento - b.valor_requerimento) * order;
          } else if (coluna === 'taxa') {
            return (a.taxa - b.taxa) * order;
          } else if (coluna === 'desconto') {
            return (a.desconto - b.desconto) * order;
          } else if (coluna === 'total_req') {
            return (a.total_requerimento - b.total_requerimento) * order;
          } else if (coluna === 'data_solicitacao') {
            return a.data_registro.localeCompare(b.data_registro) * order;
          } else if (coluna === 'data_analise_inicio') {
            return this.ordenarPorData(a.data_inicio_analise, b.data_inicio_analise, order);
          } else if (coluna === 'data_analise_fim') {
            return this.ordenarPorData(a.data_fim_analise, b.data_fim_analise, order);
          } else if (coluna === 'data_pagto') {
            return this.ordenarPorData(a.data_pagto, b.data_pagto, order);
          } else if (coluna === 'data_rejeitado') {
            return this.ordenarPorData(a.data_rejeicao, b.data_rejeicao, order);
          } else {
              return 0;
          }
        });
      },
      ordenarPorData(a, b, order) {
        // Verifica se ambos os valores são nulos ou iguais a "Sem data"
        if ((!a || a === 'Sem data') && (!b || b === 'Sem data')) {
          return 0;
        }
        // Verifica se apenas um dos valores é nulo ou "Sem data" e coloca por último
        if (!a || a === 'Sem data') {
          return 1 * order;
        }
        if (!b || b === 'Sem data') {
          return -1 * order;
        }
        // Ordena as datas restantes normalmente
        const dataA = new Date(a);
        const dataB = new Date(b);
        return (dataA > dataB ? 1 : -1) * order;
      },
      criaTooltip(e){

        return `<div class="text-left">
                  Aluno: ${e.nome_aluno}<br>
                  Tipo Req.: ${e.nome_requerimento}<br>
                  Data Solic.: ${this.formataDataT(e.data_registro)}<br>
                  Inicio Análise: ${e.data_inicio_analise ? this.formataDataT(e.data_inicio_analise) : '-'}<br>
                  Fim Análise: ${e.data_fim_analise ? this.formataDataT(e.data_fim_analise) : '-'}<br>
                  Valor Total Req.: R$ ${e.total_requerimento ? this.formataPreco(e.total_requerimento) : this.formataPreco(0)}<br>
                </div>`;
      },
      exibeModalVerDetalhe(e){
        this.fastRequisicoesRel.detalhesRequerimento = e
        this.showModal('modalVerLog')
      },
    },
  };
  </script>
  
  <style scope>
  .container{
    max-width: 1280px;
  }
  .sugestao-lista {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #d8d8d8;
    border-top: none;
    padding: 0;
    margin: 0;
    z-index: 999;
  }
  .sugestao-item {
    list-style-type: none;
    padding: 3px 6px;
    cursor: pointer;
    color: rgb(95, 95, 95);
  }
  .sugestao-item:hover {
    transition: 0.3s;
    background-color: #e8e8e8;
  }
  .date-picker-filtro  {
    text-align: center;
  }
  .btn-custom-primary{
    background-color: #007bff!important;
    color: #fff!important;
  }
  .btn-custom-outline-primary{
    background-color: #fff!important;
    color: #007bff!important;
    border:1px solid #007bff!important;
  }

  </style>
  