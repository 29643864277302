import { render, staticRenderFns } from "./HomeInternoSecretariaRelatoriosRequisicoesAlunos.vue?vue&type=template&id=7f2a3b0a"
import script from "./HomeInternoSecretariaRelatoriosRequisicoesAlunos.vue?vue&type=script&lang=js"
export * from "./HomeInternoSecretariaRelatoriosRequisicoesAlunos.vue?vue&type=script&lang=js"
import style0 from "./HomeInternoSecretariaRelatoriosRequisicoesAlunos.vue?vue&type=style&index=0&id=7f2a3b0a&prod&scope=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports